import React from 'react';
import './index.css'
export default class Serve extends React.Component{
    constructor(){
        super();
        this.state={
            list:[
                {
                    img_url:require('../../images/list-1.png'),
                    icon_url:require('../../images/icon-1.png')
                },
                {
                    img_url:require('../../images/list-2.png'),
                    icon_url:require('../../images/icon-2.png')
                },
                {
                    img_url:require('../../images/list-3.png'),
                    icon_url:require('../../images/icon-3.png')
                },
                {
                    img_url:require('../../images/list-4.png'),
                    icon_url:require('../../images/icon-4.png')
                },
                {
                    img_url:require('../../images/list-5.png'),
                    icon_url:require('../../images/icon-5.png')
                },
                {
                    img_url:require('../../images/list-6.png'),
                    icon_url:require('../../images/icon-6.png')
                },
                {
                    img_url:require('../../images/list-7.png'),
                    icon_url:require('../../images/icon-7.png')
                },
                {
                    img_url:require('../../images/list-8.png'),
                    icon_url:require('../../images/icon-8.png')
                }
            ],
            activeIndex:0
        }
    }
    componentDidMount(){
        // let index=0;
        // setInterval(()=>{
        //     if(index>6){
        //         index=0
        //     }else{
        //         index++;
        //     }
        //     this.setState({
        //         activeIndex:index
        //     })
        // },2000)
    }
   handleMouseEnter(index){
       this.setState({
           activeIndex:index
       })
   }
    render(){
        const list=this.state.list;
        return(
            <div className="serve">
                {
                    list.map((item,index)=>(

                        <div className="list-item" key={index} onMouseEnter={this.handleMouseEnter.bind(this,index)}>
                            {this.state.activeIndex == index && <div className='light'><img src={require('../../images/light.png')} alt=""/></div>}
                            <div className={this.state.activeIndex == index?'icon active':'icon'}><img src={item.icon_url} alt=""/></div>
                            <div className="list-pic"><img src={item.img_url} alt=""/></div>
                        </div>
                    ))
                }
            </div>
        )
    }
}