import React from 'react';
import './home.css';
import { Link } from 'react-router-dom'

class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navList: [
                {
                    name: '首页',
                    id: 0,
                    to: '/'
                },
                {
                    name: '服务内容',
                    id: 1,
                    to: '/serve'
                },
                {
                    name: '精品案例',
                    id: 2,
                    to: '/case'
                },
                {
                    name: '关于珍数',
                    id: 3,
                    to: '/about'
                },
                {
                    name: '联系我们',
                    id: 4,
                    to: '/concat'
                }
            ],
            activeIndex: 0
        }
    }
    componentDidMount() {
        this.props.history.listen((link) => {
            console.log(link.pathname);
            this.state.navList.map((item) => {
                if (item.to == link.pathname) {
                    this.setState({
                        activeIndex: item.id
                    })
                }
            })
        })
        // window.addEventListener('hashchange', () => {
        //     const url = this.props.match.url;
        //     this.state.navList.map((item) => {
        //         if (item.to == url) {
        //             this.setState({
        //                 activeIndex: item.id
        //             })
        //         }
        //     })
        // })
        // const url = this.props.match.url;
        // this.state.navList.map((item) => {
        //     if (item.to == url) {
        //         this.setState({
        //             activeIndex: item.id
        //         })
        //     }
        // })
    }
    changeNav(index) {
        this.setState({
            activeIndex: index
        })
    }
    render() {
        const navData = this.state.navList;
        return (
            <div className='slider'>
                <Link to='/'>
                    <div className='logo' onClick={this.changeNav.bind(this, 0)}><img src={require("../images/logo.png")} alt="" /></div>
                </Link>
                <div className="nav">
                    {navData.map((item, index) => {
                        return (
                            <div className='nav-item' key={item.id}>
                                <div className={this.state.activeIndex == index ? 'nav-list-hd active' : 'nav-lis-hd'}
                                    onClick={this.changeNav.bind(this, index)}>
                                    <Link to={item.to}>
                                        <div className="item">{item.name}</div>
                                    </Link>
                                </div>
                                {index != (navData.length - 1) && <div className="dot">·</div>}
                            </div>

                        )
                    })}
                </div>
                <div className="phone"><img src={require('../images/phone.png')} alt="" /></div>
            </div>
        )
    }
}

export default Slider;