
//定义常量及api接口地址
export const access_token='';
export const platform='';
// eslint-disable-next-line
let env='develop';
//开发环境、测试环境、生产环境
if(window.location.hostname == 'localhost'){
    env='develop';
}else if(window.location.hostname == ''){
    env='develop';
}else{
    env='product';
}

export const ENV = env;
export const HOSTS={
    develop:{
        api:'http://sf.qianhai12315.com/offcial',  //测试环境
        domain:''
    },
    product:{
        api:'http://sf.qianhai12315.com/offcial', //生产环境
        domain:''
    }
};

export const ROOT = HOSTS[ENV].api;

export const API = {
    memberInfo:ROOT
};
