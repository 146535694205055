import axios from 'axios';
import qs from 'qs';
import {API,platform,access_token} from "./config";
// 把默认配置withCredentials改为true，就可以允许跨域携带cookie信息了
axios.defaults.withCredentials = false;
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.baseURL=API.memberInfo;
axios.defaults.changeOrigin=true;
//axios拦截器
axios.interceptors.request.use(request => {
   // if(request.params){
   //     for(let key in request.params){
   //         request.params[key] || (delete request.params[key]);
   //     }
   //     request.params.access_token  = access_token;
   //     request.params.platform || (request.params.platform = platform);
   // }else{
   //     request.params={
   //         platform,
   //         access_token:access_token
   //     }
   // }
    if(request.method === 'post'){
        request.data=qs.stringify(request.data);
    }
   return request;
});

axios.interceptors.response.use(response=>{
    //在这里你可以判断后台返回数据携带阐述的请求码
    if(response.status === 200){
        return response.data;
    }else{
        //非200请求
        throw Error(response.data.message || '服务异常')
    }
});
export default {
    getCase(data){
        return axios.get('/case/get_all',{params:data})
    }
}

