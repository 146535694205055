import React from 'react';
import './index.css';
export default class concat extends React.Component{
    constructor(){
        super()
    }
    render(){
        return(
            <div className='concat'>
                <img src={require("../../images/concat.png")} alt=""/>
            </div>
        )
    }
}