import React from 'react';
import './index.css';
import { Link } from 'react-router'
import api from '../../common/api'
import QRCode from 'qrcodejs2';

export default class caseInfo extends React.Component {
    constructor() {
        super();
        this.state = {
            case_list: [],
            page_num: 1,
            total_page: 1,
            hoverIndex: ''
        }
    }

    componentDidMount() {
        this.getAll(this.state.page_num);
    }

    getAll(page_num) {
        api.getCase({
            page_num: page_num,
            page_size: 5
        }).then(res => {
            console.log(res);
            if (res.ret == 200) {
                this.setState({
                    total_page: res.data.total_page,
                    case_list: res.data.case_list
                });
                console.log(this.state.case_list);
            }
        })
    }

    toggleHover(index, link, classify) {
        console.log(link)
        console.log(classify)
        this.setState({
            hoverIndex: index
        });
        let str = 'code' + index;
        if (this.state.qrCode) {
            // this.state.qrCode.clear();
            // qrCode.clear()
        }
        if (classify == 1) {
            document.getElementById(str).innerHTML = '';
            var qrcode = new QRCode(str, {
                text: link,
                width: 130,
                height: 130,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H
            });
        }
        if (classify == 2) {
            return;
        }
        this.setState({
            qrCode: qrcode
        })
    }

    toggleLeave() {
        this.setState({
            hoverIndex: ''
        })
    }

    preEvt() {
        if (this.state.page_num > 1) {
            this.state.page_num--;
            this.getAll(this.state.page_num);
        }
    }

    nextEvt() {
        if (this.state.page_num < this.state.total_page) {
            console.log("next");
            this.state.page_num++;
            this.getAll(this.state.page_num);

        }
    }

    render() {
        const case_list = this.state.case_list;
        return (
            <div className='case'>
                <div className="case-con">
                    {case_list.map((item, index) => {
                        return (
                            <div className="case-list" key={index}
                                onMouseEnter={this.toggleHover.bind(this, index, item.link, item.classify)}
                                onMouseLeave={this.toggleLeave.bind(this, index)}>
                                <div className='case-item'>
                                    <div className="img-con" style={{ background: 'url(' + item.img_url + ') center center /cover' }}>
                                        {/*<img src={item.img_url} alt=""/>*/}
                                    </div>
                                    <div className="pic">
                                        {/*<img src={require("../../images/outline.png")} alt=""/>*/}
                                        <div className={this.state.hoverIndex === index ? 'cover active' : 'cover'}>
                                            {item.classify == 1 && <div className="code" id={'code' + index}></div>}
                                            {item.classify == 2 && <img className='program' src={item.qrcode} alt="" />}
                                        </div>
                                    </div>
                                </div>
                                <div className="case-name">{item.title}</div>
                            </div>
                        )
                    })}
                    <div className="left-btn case-bar" onClick={this.preEvt.bind(this)}><img
                        src={require('../../images/left.png')} alt="" /></div>
                    <div className="right-btn case-bar" onClick={this.nextEvt.bind(this)}><img
                        src={require('../../images/right.png')} alt="" /></div>
                </div>
                <div className='know-us'><a href="#/detail"><img src={require("../../images/know-more.png")}
                    alt="" /></a></div>
            </div>
        )
    }
}