import React from 'react';
import Swiper from 'swiper'
import '../../common/swiper.min.css'
import {Link} from 'react-router-dom'
export default class home extends React.Component{
    constructor(){
        super();
    }
    componentDidMount(){
        var mySwiper=new Swiper(".swiper-container",{
            autoplay:false
        })
    }
    render(){
        return(
            <div className="home">
                <div className="home-bg">
                    {/*<img src="../../images/sjx.png" alt=""/>*/}
                </div>
              <div className="swiper-container">
                  <div className="swiper-wrapper">
                      <div className="swiper-slide"><div className="page-1 page-con">
                          <div className="text text-1"><img src={require("../../images/text-1.png")} alt=""/></div>
                          <div className="bg"><img src={require('../../images/page-1.png')} alt=""/></div>
                      </div>
                      </div>
                      <div className="swiper-slide"><div className="page-2 page-con">
                          <div className="bg"><img src={require("../../images/page-2.png")} alt=""/></div>
                          <div className="text text-2"><img src={require("../../images/text-2.png")} alt=""/></div></div></div>
                      <div className="swiper-slide"><div className="page-3 page-con">
                          <div className="bg"><img src={require("../../images/page-3.png")} alt=""/></div>
                          <div className="text text-3"><img src={require("../../images/text-3.png")} alt=""/></div></div></div>
                  </div>
              </div>
                <Link to='/about'><div className="know-us"><img src={require("../../images/know-us.png")} alt=""/></div></Link>
                <div className="mouse"><img src={require("../../images/mouse.png")} alt=""/></div>
            </div>
        )
    }
}