import React from 'react';
import './index.css';
export default class about extends React.Component{
    constructor(){
        super();
    }
    render(){
        return(
            <div className='about'>
                <img src={require("../../images/about.png")} alt=""/>
            </div>
        )
    }
}