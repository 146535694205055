import React from 'react';
import './index.css';
import api from '../../common/api'
import QRCode from 'qrcodejs2';
export default class detail extends React.Component {
  constructor() {
    super();
    this.state = {
      navIndex: 0,
      case_list: [],
      hoverIndex: '',
      page_num: 1,
      total_page: 1,
      qrCode: null
    }
  }
  componentDidMount() {
    this.getAll(this.state.page_num, 1);
  }
  changeNav(index) {
    this.setState({
      navIndex: index
    });
    this.state.now_page = 1;
    this.setState({
      case_list: []
    });
    this.getAll(1, index + 1);
  }
  toggleHover(index, link, classify) {
    if (classify === 5) {
      return
    }
    console.log("12");
    this.setState({
      hoverIndex: index
    });
    let str = 'code' + index;
    if (this.state.qrCode) {
      this.state.qrCode.clear();
    }
    if (classify == 2) {
      return;
    }
    document.getElementById(str).innerHTML = '';
    let qrcode = new QRCode(str, {
      text: link,
      width: 140,
      height: 140,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: QRCode.CorrectLevel.H
    });
    this.setState({
      qrCode: qrcode
    })
  }
  toggleLeave(index, classify) {
    if (classify === 5) {
      return
    }
    this.setState({
      hoverIndex: ''
    });
    this.state.qrCode.clear();
  }
  linkHref(link) {
    window.location.href = link
  }
  getAll(page_num, classify) {
    api.getCase({
      page_num: page_num,
      page_size: 100,
      classify: classify
    }).then(res => {
      console.log(res);
      if (res.ret == 200) {
        this.setState({
          total_page: res.data.total_page,
          case_list: res.data.case_list
        });
        console.log(this.state.case_list);
      }
    })
  }
  render() {
    const case_list = this.state.case_list;
    return (
      <div className='detail'>
        <div className="hd"><img src={require('../../images/detail-hd.png')} alt="" /></div>
        <div className="detail-nav">
          <div className={this.state.navIndex == 0 ? 'nav-list active' : 'nav-list'}
            onClick={this.changeNav.bind(this, 0)}>H5 & 互动</div>
          <div className={this.state.navIndex == 1 ? 'nav-list active' : 'nav-list'}
            onClick={this.changeNav.bind(this, 1)}>小程序 & 创意</div>
          <div className={this.state.navIndex == 2 ? 'nav-list active' : 'nav-list'}
            onClick={this.changeNav.bind(this, 2)}>VR & 开发</div>
          <div className={this.state.navIndex == 4 ? 'nav-list active' : 'nav-list'}
            onClick={this.changeNav.bind(this, 4)}>PC系统</div>
        </div>
        <div className='case-con'>
          {case_list.map((item, index) => {
            return (
              <div className={item.classify != 5 ? 'case-list' : 'case-list-pc'} key={index} onMouseEnter={this.toggleHover.bind(this, index, item.link, item.classify)} onMouseLeave={this.toggleLeave.bind(this, index, item.classify)}>
                <div className='case-item' onClick={this.linkHref.bind(this, item.link)}>
                  {item.classify != 5 ? <div className="img-con" style={{ background: 'url(' + item.img_url + ') center center /cover' }}>
                    {/*<img src={item.img_url} alt=""/>*/}
                  </div> : <div className="img-con" >
                    <img src={item.img_url} alt="" />
                  </div>}

                  <div className="pic">
                    {/*<img src={require("../../images/outline.png")} alt=""/>*/}
                    <div className={this.state.hoverIndex === index ? 'cover active' : 'cover'}>
                      <div className="code" id={'code' + index}>
                        {item.classify == 2 && <img src={item.qrcode} alt="" />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="case-name">{item.title}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}