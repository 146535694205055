// eslint-disable-next-line
import React from 'react';
import 'antd/dist/antd.css';
import {Layout} from  'antd';
import {SliderMenu,ContentRoute} from './routers/index';
import './App.css';
const {Header,Content}=Layout;
function App() {
  return (
    <div className="App">
     <Layout>
       <Header>
           <SliderMenu/>
       </Header>
       <Content>
           <ContentRoute />
       </Content>
     </Layout>
        <div className="tip">版权所有 © 2015-2016 珍数科技（深圳）有限公司  |  <a href="https://beian.miit.gov.cn">粤ICP备17030724号</a></div>
    </div>
  );
}
export default App;