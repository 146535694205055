import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom'
import Slider from '../compontents/sideBar'
import Home from '../page/Home/index';
import Serve from '../page/Serve/index';
import About from '../page/About/index';
import Concat from '../page/Concat/index';
import Case from '../page/Case/index';
import Detail from '../page/Detail/index';
export const SliderMenu=()=> <Route path='*' component={Slider}></Route>
export const ContentRoute=()=>(
    <Switch>
        <Route exact component={Home} path='/'></Route>
        <Route exact component={Serve} path='/serve'></Route>
        <Route exact component={About} path='/about'></Route>
        <Route exact component={Concat} path='/concat'></Route>
        <Route exact component={Case} path='/case'></Route>
        <Route exact component={Detail} path='/detail'></Route>
    </Switch>
);